import React, { useEffect } from 'react'
import Head from 'next/head'
import { useAuth } from 'src/contexts/Auth'
import { routes } from 'src/constants/routes'
import { useRouter } from 'src/api/router'
import { Landing } from 'src/components/Landing'

function Top() {
  const { auth: user } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (user && !user.isAnonymous && user.emailVerified) {
      router.replace(routes.home)
    }
  }, [user?.isAnonymous, user?.emailVerified])

  return (
    <>
      <Landing />
    </>
  )
}

export default Top
